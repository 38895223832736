/*
 * ----------------------------------------------------------------------------
 * Support
 * ----------------------------------------------------------------------------
 */

Neo.namespace('Support');

/*
 * ----------------------------------------------------------------------------
 * Lightbox: Blueimp Gallery
 * ----------------------------------------------------------------------------
 */
Neo.namespace('Support.Lightbox');

Neo.Support.Lightbox = function($) {

    var init = function(element) {

        document.getElementById(element).onclick = function(event) {

            event = event || window.event;

            var target = event.target || event.srcElement,
                link = target.src ? target.parentNode : target,
                options = {index: link, event: event},
                links = this.getElementsByTagName('a');

            blueimp.Gallery(links, options);

          };

      };

    return {
        init: init
      };

  }(jQuery);

//Exemplo de Chamada
if ($('#js-gallery').length) {
  Neo.Support.Lightbox.init('js-gallery');
}

/*
 * ----------------------------------------------------------------------------
 * Vídeos Responsivos no Redactor
 * ----------------------------------------------------------------------------
 */

Neo.namespace('Support.VideoResponsivo');

Neo.Support.VideoResponsivo = function($) {

    var init = function() {

        $('.redactor iframe').each(function(index) {

            $(this).addClass('embed-responsive-item');
            $(this).wrap('<div class=\'embed-responsive embed-responsive-4by3\'></div>');

          });

      };

    return {
        init: init
      };

  }(jQuery);

//Exemplo de Chamada
if ($('.redactor').length) {
  Neo.Support.VideoResponsivo.init();
}

/*
 * ----------------------------------------------------------------------------
 * Máscaras comuns entre diferentes forms (contato, currículo, etc)
 * ----------------------------------------------------------------------------
 */

$(document).ready(function() {

    $('.js-mask-fone').inputmask('mask', {'mask': '(99) 99999999[9]'});
    $('.js-mask-date').inputmask('d/m/y', {'placeholder': 'dd/mm/aaaa'});
    $('.js-mask-cep').inputmask('mask', {'mask': '99999-999'});

  });


/*
 * ----------------------------------------------------------------------------
 * Bootstrap tooltips
 * ----------------------------------------------------------------------------
 */
$(function() {
    $('a[data-toggle="tooltip"]').tooltip();
  });
